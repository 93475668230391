var brands = document.querySelectorAll(
    ".page-brands .lux-brands-wrapper .lux-brand",
);
var luxRight = document.querySelector(
    ".page-brands .lux-brands-container .lux-right",
);

brands.forEach(function (brand) {
    brand.addEventListener("mouseover", function () {
        var brandInfoClone = brand
            .closest(".lux-brand-wrapper")
            .querySelector(".lux-brand-info")
            .cloneNode(true);

        luxRight.innerHTML = "";
        luxRight.appendChild(brandInfoClone);
    });
});
