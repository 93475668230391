const brandDiscountButton = document.getElementById("brand-discount-confirm");
const brandPopupTriggers = document.querySelectorAll(
    ".lux-brand[data-toggle='lux-popup']",
);
const modalBrandNavLinks = document.querySelectorAll(
    ".brand-discount-modal .brand-nav-link",
);

if (brandDiscountButton) {
    brandDiscountButton.addEventListener("click", function (event) {
        event.preventDefault();

        const brandId = this.getAttribute("data-brand-id");
        const brandDiscountToast = document.querySelector(
            ".toast-brand-discount",
        );

        fetch("/add-brand-discount", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-TOKEN": "{{ csrf_token() }}",
            },
            body: JSON.stringify({
                brand_id: brandId,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    document
                        .querySelectorAll(".brand-discount-modal")
                        .forEach((el) => el.classList.remove("active"));

                    luxPopup.toastShow(brandDiscountToast);

                    setTimeout(() => {
                        window.location.reload();
                    }, 1500);
                } else {
                    throw new Error(data.message);
                }
            })
            .catch((error) => {
                throw new Error(error);
            });
    });
}

if (brandPopupTriggers) {
    brandPopupTriggers.forEach(function (brand) {
        brand.addEventListener("click", function () {
            let brandName = this.getAttribute("data-brand-name");
            let brandImg = this.getAttribute("data-brand-img");
            let brandLink = this.getAttribute("data-brand-link");
            let brandId = this.getAttribute("data-brand-id");

            document.getElementById("brand-discount-name").textContent =
                brandName;
            document.getElementById("toast-brand-discount-name").textContent =
                brandName;
            document
                .getElementById("brand-discount-img")
                .setAttribute("src", brandImg);
            document
                .getElementById("brand-discount-img")
                .setAttribute("alt", brandName);
            document
                .getElementById("brand-discount-confirm")
                .setAttribute("data-brand-id", brandId);
        });
    });
}

if (modalBrandNavLinks) {
    modalBrandNavLinks.forEach((link) => {
        link.addEventListener("click", function (e) {
            e.preventDefault();

            let swiperId = "swiper-brand-discount";
            let swiperInstance = window.swipers[swiperId];

            if (!swiperInstance) return;

            let targetId = this.getAttribute("data-target");
            let targetElement = document.querySelector(
                `.brand-discount-modal #${targetId}`,
            );

            if (!targetElement) return;

            let swiperWrapper = document.querySelector(
                `#${swiperId} .swiper-wrapper`,
            );
            let offsetTop = targetElement.offsetTop - swiperWrapper.offsetTop;

            swiperInstance.setTranslate(-offsetTop);
            swiperInstance.update();
        });
    });
}
